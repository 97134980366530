import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from "axios";
import * as constants from "../../util/constants";
import bankAccount from "../../media/img/payments/bank-account.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ButtonPlaid extends Component {
    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {
        super(props);

        this.state = {
            text: this.props.text,
            text_id: this.props.id
        };

        this.loadScript('https://cdn.plaid.com/link/v2/stable/link-initialize.js')
            .then(() => {})
            .catch(this.onScriptError);
    }

    render_text() {
        const text = this.props.text;
        const text_id = this.state.text_id;

        if (text_id != null) {
            return <FormattedMessage id={text_id} />;
        }
        return text;
    }

    onScriptError(error) {
        console.error("There was an issue loading the link-initialize.js script. Error: " + error);
    }
    onScriptLoaded() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/bank_account/createfromplaid/${this.props.countryCode}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
            }
        }).then(response => {

            let props = {
                clientName: response.data?.clientName,
                env: response.data?.env,
                key: response.data?.publicKey,
                onExit: this.props.onExit,
                onEvent: this.props.onEvent,
                onSuccess: this.props.onSuccess,
                product: response.data?.product,
                selectAccount: response.data?.selectAccount,
                token: response.data.token,
                webhook: response.data?.webhook,
            };

            window.linkHandler = window.Plaid.create(props);

        }).catch(error => {});
    }
    loadScript(src) {
        return new Promise(function (resolve, reject) {
            if (document.querySelector('script[src="' + src + '"]')) {
                resolve();
                return;
            }

            const el = document.createElement("script");

            el.type = "text/javascript";
            el.async = true;
            el.src = src;

            el.addEventListener("load", resolve);
            el.addEventListener("error", reject);
            el.addEventListener("abort", reject);

            document.head.appendChild(el);
        });
    }

    handleOnClick() {

        this.onScriptLoaded();

        const institution = this.props.institution || null;

        setTimeout(() => {

            if(window.linkHandler){

                window.linkHandler.open(institution);
            }

        },800);
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic alert bar that appears above a form in response to the submission of the form.
     */
    render() {
        let self = this;
        // const id = this.state.text_id;
        const type = this.props.type;

        return (
        <div className="list-group mb-2">

            {
                this.props.type !== 'PAYBYTEXT' && this.props.type !== 'HOSTED'  &&
                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.handleOnClick()}>
                    <div className="row align-items-center">
                        <div className="col-8">
                            {self.render_text()}
                        </div>
                        <div className="col text-right">
                            <div className="float-right">
                                <img src={bankAccount} className="rounded border w-75" alt="Enter your Bank Account information"/>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                this.props.type === 'HOSTED' &&
                <div className="list-group mb-2">
                    <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.handleOnClick()}>
                        <div className="row align-items-center">
                            <div className="col-8">
                                {self.render_text()}
                            </div>
                            <div className="col text-right">
                                <div className="float-right">
                                    <img src={bankAccount} className="rounded border w-75" alt="Enter your Bank Account information"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                this.props.type === 'PAYBYTEXT' &&
                <div
                    key={'plaid'}
                    id={'plaid'}
                    className={this.props.className}
                    onClick={() => this.handleOnClick()}
                >
                    <div className='media'>
                        <div className='align-self-center mr-4 text-center'><FontAwesomeIcon icon={['far', 'university']} className='fa-lg' /></div>
                        <div className='media-body align-self-center'>Connect Bank Account via Plaid</div>
                        <div className='align-self-center mx-2 text-center'>--</div>
                    </div>
                </div>
            }
        </div>
        );
    }
}

ButtonPlaid.defaultProps = {
    type: 'submit',
    btnType: 'btn-primary',
    btnSize: 'btn-lg',
    disabled: false,
    spinner: false,
    plaidUrl: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
};

export default ButtonPlaid;