import React from "react";
import { injectIntl } from "react-intl";
import Propertii from "../../common/Propertii";
import TransactionReport from "../../common/ReportExceededPaymentThresholds";

class ReportExceededPaymentThresholds extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company complete transactions report component.
     */
    render() {

        return(
            <TransactionReport companyId={this.props.match.params.companyId} />
        )
    };
}

export default injectIntl(ReportExceededPaymentThresholds);