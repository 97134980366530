import axios from "axios";
import React from "react";
import { injectIntl, intlShape } from "react-intl";
import customReportIcon from "../../../media/img/icons/iconcustomreports.svg";
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";
import NavListItem from "../../common/NavListItem";
import Propertii from "../../common/Propertii";

class Account extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      manageBalance: false,
    };
  }

  /**
   * Important!
   * The following api call provide us the information about whether a manager is managing charges or not.
   * We already have a smilar check present in the Dashboard.js. Since Dashboard and Reports are sibling components,
   * the best solution would have been to lift the state up and use the same value across both the components.
   * However, to reduce complexity and since the migration is in process I decided to have this additional call in the
   * Reports Component instead.
   */
  componentDidMount() {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/company/search`,
        {
          orderBy: "ASC",
          orderByFields: ["createDate"],
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        if(response.data.records.length > 0) {
          const propertiesArr = response.data.records;
          propertiesArr.forEach(property => {
            if(property.manageBalance !== undefined && property.manageBalance === "YES") {
              this.setState((prevState) => ({
                ...prevState,
                manageBalance: true,
              }));
            }
          });
        } else {
          this.setState((prevState) => ({
            ...prevState,
            manageBalance: false,
          }));
        }
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Render the component.
   *
   * @returns {*} - The customer account navigation component.
   */
  render() {
    return (
      <div className="content-block">
        <div className="container">
          <Breadcrumb parentPage="Reports" />

          <div className="card">
            <div className="card-header">Options</div>
            <div className="card-body card-body-list">
              <div className="list-group">
                {/* ***************************
                  This following check will only display overdue charges if the managers manage their charges 
                  and one time payments are used.
                  Bugfix: LP-841, @author Arsh Uppal 
                *************************** */}
                {this.state.manageBalance && (
                  <NavListItem
                    path="/manager/reports/overdue"
                    iconType="far"
                    iconName="envelope-open-dollar"
                    name="Overdue Charges"
                    size="large"
                    description="View unpaid overdue charges for your tenants."
                  />
                )}
                <NavListItem
                  path="/manager/reports/transactions"
                  iconType="far"
                  iconName="credit-card"
                  name="Transactions"
                  size="large"
                  description="View a detailed history of completed transactions by your tenants."
                />
                <NavListItem
                  path="/manager/reports/txt2pay"
                  iconType="far"
                  iconName="sms"
                  name="Txt2Pay"
                  size="large"
                  description="View text messages sent to your tenants."
                />
                <NavListItem
                  path="/manager/reports/efts"
                  iconType="far"
                  iconName="piggy-bank"
                  name="EFTs"
                  size="large"
                  description="View sent EFT batches and their individual transactions."
                />
                <NavListItem
                  path="/manager/reports/tenants"
                  iconType="far"
                  iconName="users"
                  name="Tenants"
                  size="large"
                  description="View tenants and their assigned property information."
                />
                <NavListItem
                  path="/manager/reports/tenantapplications"
                  iconType="far"
                  iconName="envelope"
                  name="Tenant Applications"
                  size="large"
                  description="View tenant applications and track application fees."
                />
                <NavListItem
                    path="/manager/reports/paymentmethodAudit"
                    iconType="far"
                    iconName="file-chart-pie"
                    name="Payment Methods"
                    size="large"
                    description="Audit create, delete, and add/remove to autopayment actions associated with payment methods."
                />
                <NavListItem
                    path="/manager/reports/exceededpaymentthresholds"
                    iconType="far"
                    iconName="credit-card"
                    name="Exceeded Thresholds"
                    size="large"
                    description="View a detailed history of Exceeded Thresholds by your tenants."
                />
                <NavListItem
                  path="/manager/reports/customreports"
                  customReportIconSrc={customReportIcon}
                  name="CSV Reports Library"
                  size="large"
                  description="Download custom CSV exports"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Account.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Account);
