import axios from "axios";
import lodash from "lodash";
import React from "react";
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldPhone from "../../common/FieldPhone";
import FieldPropertiesAsync from "../../common/FieldPropertiesAsync";
import FieldText from "../../common/FieldText";
import Propertii from "../../common/Propertii";
import SelectManagerRoleComponent from "../../common/SelectManagerRoleComponent";

class Manager extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      manager: {},

      propertyQuery: {
        orderBy: "ASC",
        orderByFields: ["street1"],
      },

      propertyList: {
        page: "",
        recordsPerPage: "",
        totalPages: "",
        totalRecordCount: "",
        records: [{}],
      },

      managedPropertyList: [],

      validationList: [],

      roles: {
        PRIMARY_MANAGER: false,
        SUPPORT_MANAGER: false,
        PROPERTY_MANAGER: false,
        FINANCE_MANAGER: false,
        LEASING_MANAGER: false,
      },

      receiveAlertsBool: false,
      
    };

    this.saveManager = this.saveManager.bind(this);
    this.searchProperties = this.searchProperties.bind(this);
    this.handleSearchProperties = this.handleSearchProperties.bind(this);
    this.handleChangeManagedProperties = this.handleChangeManagedProperties.bind(
      this
    );
    this.handleChangeMakeTenantPayments = this.handleChangeMakeTenantPayments.bind(this);
    this.handleChangeReceiveAlertsReminder = this.handleChangeReceiveAlertsReminder.bind(this);
  }

  /**
   * Fetch the manager on mounting of the component.
   */
  componentDidMount() {
    const { formatMessage } = this.props.intl;

    axios
      .get(
        `${constants.REACT_APP_HOST_API_URL}/manager/${this.props.match.params.managerId}`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {

        let receiveAlertsBoolean = response?.data?.emailNotifications?.exceededPaymentThresholdsForPm;
        this.setState((prevState) => ({
          ...prevState,
          manager: response.data,
          receiveAlertsBool: receiveAlertsBoolean,
          roles: {
            PRIMARY_MANAGER: response.data.roles?.includes('PRIMARY_MANAGER'),
            SUPPORT_MANAGER: response.data.roles?.includes('SUPPORT_MANAGER'),
            PROPERTY_MANAGER: response.data.roles?.includes('PROPERTY_MANAGER'),
            FINANCE_MANAGER: response.data.roles?.includes('FINANCE_MANAGER'),
            LEASING_MANAGER: response.data.roles?.includes('LEASING_MANAGER'),
          }
        }));

        axios
          .post(
            `${constants.REACT_APP_HOST_API_URL}/company/${response.data.companyId}/properties?recordsPerPage=2000&page=1`,
            this.state.propertyQuery,
            {
              headers: this.generateRequestHeaders(),
            }
          )
          .then((response) => {
            let propertyList = response.data.records;

            this.setState((prevState) => ({
              ...prevState,
              propertyList: propertyList,
            }));

            axios
              .get(
                `${constants.REACT_APP_HOST_API_URL}/manager/${this.state.manager.id}/properties`,
                {
                  headers: this.generateRequestHeaders(),
                }
              )
              .then((response) => {
                let managedPropertyList = [];

                response.data.records.forEach(function (managedProperty) {
                  // propertyList.forEach((property, key) => {
                  //   if (property.id === managedProperty.id) {
                      managedPropertyList.push({
                        value: managedProperty?.id,
                        label:
                        managedProperty?.street1 +
                          (managedProperty?.street2 ? ", " + managedProperty?.street2 : "") +
                          ", " +
                          managedProperty?.city +
                          ", " +
                          (managedProperty?.country === "CA"
                            ? formatMessage({
                                id: "province." + managedProperty?.province,
                              })
                            : formatMessage({
                                id: "state." + managedProperty?.province,
                              })) +
                          ", " +
                          formatMessage({ id: "country." + managedProperty?.country }) +
                          " " +
                          managedProperty?.postalCode,
                      });
                  //   }
                  // });
                });

                this.setState((prevState) => ({
                  ...prevState,
                  managedPropertyList: managedPropertyList,
                }));
              })
              .catch((error) => {
                this.handleValidation(error);
              });
          })
          .catch((error) => {
            this.handleValidation(error);
          });
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Handle the submission of the form.
   *
   * @param event - The event container.
   */
  saveManager(event) {
    event.preventDefault();

    let managedPropertyList = [];

    this.state.managedPropertyList.forEach((managedProperty, key) => {
      managedPropertyList.push(managedProperty.value);
    });

    let rolesArr = [];
    if(this.state.roles["PRIMARY_MANAGER"]) {
      rolesArr.push("PRIMARY_MANAGER")
    } else {
      Object.keys(this.state.roles).forEach((role) => this.state.roles[role] && rolesArr.push(role))
    }

    axios
      .put(`${constants.REACT_APP_HOST_API_URL}/update`, {...this.state.manager, roles: rolesArr}, {
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        axios
          .post(
            `${constants.REACT_APP_HOST_API_URL}/manager/${this.state.manager.id}/properties`,
            managedPropertyList,
            {
              headers: this.generateRequestHeaders(),
            }
          )
          .then((response) => {
            this.setState({
              validationList: [
                {
                  fields: {},
                  alert: {
                    type: "primary",
                    code: "saved",
                    message: "Changes have been saved",
                  },
                },
              ],
            });

            this.props.refreshManager(this.props.match.params.managerId);
          })
          .catch((error) => {
            this.handleValidation(error);
          });
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Handles property search based on the provided input
   *
   * @param {*} inputValue
   */
  handleSearchProperties(inputValue) {
    if (!inputValue || !this.state.manager.companyId) {
      return null;
    }

    let propertyQuery = this.state.propertyQuery;

    propertyQuery.joins = {
      company: {
        targetRecordType: "TYPE_COMPANY",
        joinField: "companyId",
        alias: "company",
        returnFields: ["name"],
      },
    };

    propertyQuery.conditionList = [];
    propertyQuery.conditionList.push(
      {
        type: "STRING",
        logicalOperator: "AND",
        openBrackets: "(",
        closeBrackets: null,
        fieldName: "id",
        operator: "EQUALS",
        fieldValue: inputValue,
      },
      {
        type: "STRING",
        logicalOperator: "OR",
        openBrackets: null,
        closeBrackets: ")",
        fieldName: "propertyName,street1,city,postalCode,propertyIdentifier",
        operator: "MATCH",
        fieldValue: inputValue,
      }
    );

    return axios.post(
      `${constants.REACT_APP_HOST_API_URL}/company/${this.state?.manager?.companyId}/properties`,
      this.state.propertyQuery,
      {
        headers: this.generateRequestHeaders(),
      }
    );
  }

  /**
   * Search properties based on the provided input.
   *
   * @param {*} inputValue
   * @param {*} callback
   */
  searchProperties(inputValue, callback) {
    const { formatMessage } = this.props.intl;

    this.handleSearchProperties(inputValue)
      .then((response) => {
        const propertyList = response?.data?.records;
        const options = [];
        if(propertyList) {
            propertyList.forEach((data) => {
            options.push({
                value: data.id,
                label:
                data.street1 +
                (data.street2 ? ", " + data.street2 : "") +
                ", " +
                data.city +
                ", " +
                (data.country === "CA"
                    ? formatMessage({ id: "province." + data.province })
                    : formatMessage({ id: "state." + data.province })) +
                ", " +
                formatMessage({ id: "country." + data.country }) +
                " " +
                data.postalCode,
            });
            });
        }
        callback(options);
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Handle changes to the managed properties field.
   *
   * @param selectedOptions - The list of selected managed properties.
   */
  handleChangeManagedProperties(selectedOptions) {
    let managedPropertyList = this.state.managedPropertyList;

    if (selectedOptions == null) {
      selectedOptions = [];
    }

    if (selectedOptions.length === 0) {
      managedPropertyList = [];
    }

    if (selectedOptions.length > 0) {
      managedPropertyList = [];

      selectedOptions.forEach((managedProperty, key) => {
        managedPropertyList.push({
          value: managedProperty.value,
          label: managedProperty.label,
        });
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      managedPropertyList: managedPropertyList,
    }));
  }

  /**
     * Handle changes to the block from making payments on behalf of the tenant
     *
     * @param event - The event container.
     */
   handleChangeMakeTenantPayments(event) {
    event.persist();
    
    let field = this.state.manager;
    field[event.target.name] = !event.target.checked;

    this.setState(prevState => ({
        ...prevState,
        manager: field,
    }));
  }

  /**
   * Save PM exceeded payment thresholds Reminder Email
   *
   */
  handleChangeReceiveAlertsReminder(){

    let receiveAlertsBoolean = !this.state.receiveAlertsBool;

    this.setState(prevState =>({
      ...prevState,
      receiveAlertsBool: receiveAlertsBoolean
    }));

    let field = this.state.manager.emailNotifications;
    field.exceededPaymentThresholdsForPm = receiveAlertsBoolean;

    this.setState(prevState => ({
      ...prevState,
      manager: {
        ...prevState.manager,
        emailNotifications: field
        }
      }));
  }


  /**
   * Render the component.
   *
   * @returns {*} - The manager profile management interface for managers.
   */
  render() {
    return (
      <React.Fragment>
        <Alert validationList={this.state.validationList} />

        <form onSubmit={this.saveManager}>
          <div className="card">
            <div className="card-header">Manager Details</div>
            <div className="card-body">
              <FieldText
                id="firstName"
                label="First Name"
                model="manager"
                parent={this}
                value={this.state.manager["firstName"]}
              />

              <FieldText
                id="lastName"
                label="Last Name"
                model="manager"
                parent={this}
                value={this.state.manager["lastName"]}
              />

              <FieldText
                id="email"
                label="Email"
                type="email"
                model="manager"
                parent={this}
                value={this.state.manager["email"]}
              />

              <FieldPhone
                id="phone"
                label="Phone"
                model="manager"
                parent={this}
                value={this.state.manager["phone"]}
                optional={true}
              />

              <FieldCheckbox
                  id="accessEventHistory"
                  label="View Audit Trail"
                  fieldLabel="This manager has access to event history"
                  model="manager"
                  parent={this}
                  value={this.state.manager["accessEventHistory"]}
              />

              <FieldCheckbox
                  id="receiveAlerts"
                  label="Receive Alerts"
                  fieldLabel="Exceeded Thresholds Reminder Email"
                  parent={this}
                  value={this.state.receiveAlertsBool}
                  handleChange={this.handleChangeReceiveAlertsReminder}
              />

              {/**
               * Select Manager role component
               * 
               * @param roles - The selected roles.
               * @param parent - This component.
               */}
              <SelectManagerRoleComponent
                roles={this.state.roles}
                parent={this}
              />

              {!this.state.roles["PRIMARY_MANAGER"] && (
                <FieldCheckbox
                  id="accessAllProperties"
                  label="Oversight"
                  disabled={this.state.roles["PRIMARY_MANAGER"]}
                  fieldLabel="This manager has access to all properties by default"
                  help="If unchecked, you must select which properties this manager has access to."
                  model="manager"
                  parent={this}
                  value={this.state.manager["accessAllProperties"]}
                />
              )}

              {this.state.propertyList.length > 0 &&
                !this.state.roles["PRIMARY_MANAGER"] &&
                !this.state.manager.accessAllProperties && (
                  <FieldPropertiesAsync
                    id="managedPropertyList"
                    label="Managed Properties"
                    labelClass="col-form-label-sm align-self-center"
                    value={this.state.managedPropertyList}
                    parent={this}
                    options={this.state.propertyList}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    handleChange={this.handleChangeManagedProperties}
                    loadOptions={lodash.debounce(this.searchProperties, 300)}
                  />
                )}

                <FieldCheckbox
                    id="muteNotification"
                    name="muteNotification"
                    label="Mute Notifications"
                    fieldLabel="Block email notifications to this Manager"
                    model="manager"
                    parent={this}
                    value={this.state.manager['muteNotification']}
                />
              
                <FieldCheckbox
                  id="makeTenantPayments"
                  fieldLabel="Block from making payments on behalf of the tenant"
                  model="manager"
                  parent={this}
                  value={!this.state.manager["makeTenantPayments"]}
                  handleChange={this.handleChangeMakeTenantPayments}
                />
              
            </div>
          </div>

          <div className="row">
            <div className="col text-right">
              <ButtonSave />
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

Manager.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Manager);
