import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldFile from "../../common/FieldFile";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Table from "../../common/Table";
import FieldChargeCodes from "../../common/FieldChargeCodes";
import charges from "../../../util/charges";
import FieldCustomTemplateMultiple from "../../common/FieldCustomTemplateMultiple";
class HostedPaymentPages extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      hostedPaymentPage: {},
      creditMerchantAccountList: {},
      customFields: {},
      document: {
        file: "",
      },
      documentList: [],
      hostedPaymentPageList: {
        page: "",
        recordsPerPage: "",
        totalPages: "",
        totalRecordCount: "",
        records: [{}],
      },
      hostedPaymentPageQuery: {
        orderBy: "DESC",
        orderByFields: ["createDate"],
        conditionList: [
          {
            type: "STRING",
            logicalOperator: "AND",
            openBrackets: null,
            closeBrackets: null,
            fieldName: "companyId",
            operator: "EQUALS",
            fieldValue: this.props.match.params.companyId,
          },
        ],
      },
      validationList: [],
      walletMerchantAccountList: {},
      selectedChargeCodes: [],
      allChargeCodes: [],
    };

    this.searchHostedPaymentPages = this.searchHostedPaymentPages.bind(this);
    this.editHostedPaymentPage = this.editHostedPaymentPage.bind(this);
    this.initHostedPaymentPage = this.initHostedPaymentPage.bind(this);
    this.saveHostedPaymentPage = this.saveHostedPaymentPage.bind(this);
    this.deleteHostedPaymentPage = this.deleteHostedPaymentPage.bind(this);

    this.searchDocuments = this.searchDocuments.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.selectDocument = this.selectDocument.bind(this);

    this.handleChangeCreditMerchantAccount = this.handleChangeCreditMerchantAccount.bind(
      this
    );
    this.handleChangeUnionPayMerchantAccount = this.handleChangeUnionPayMerchantAccount.bind(
      this
    );
    this.handleChangeApplePayMerchantAccount = this.handleChangeApplePayMerchantAccount.bind(this);
    this.handleChangeGooglePayMerchantAccount = this.handleChangeGooglePayMerchantAccount.bind(this);
    this.handleChangeChargeCodesList = this.handleChangeChargeCodesList.bind(this);
    this.handleChangeFormattedPropertyLabel = this.handleChangeFormattedPropertyLabel.bind(this);
  }

  /**
   * Load the list of hosted payment pages on mounting of the component. In addition, fetch a list of all credit and
   * bank merchant accounts to populate the merchant account fields for the hosted payment pages.
   */
  componentDidMount() {
    let companyId = this.props.match.params.companyId;

    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "VISA");
    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "VISA_ELECTRON");
    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "MASTERCARD");
    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "MAESTRO");
    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "AMERICAN_EXPRESS");
    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "DISCOVER");
    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "DINERS_CLUB");
    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "JCB");
    this.getMerchantAccounts(companyId, "TYPE_CREDIT_CARD", "UNION_PAY");
    this.getMerchantAccounts(companyId, "TYPE_BANK_ACCOUNT");
    this.getMerchantAccounts(companyId, "TYPE_PAY_PAL");
    this.getMerchantAccounts(companyId, "TYPE_CASH");
    this.getMerchantAccounts(companyId, null);
    this.getMerchantAccounts(companyId, "TYPE_WALLET", "APPLE_PAY");
    this.getMerchantAccounts(companyId, "TYPE_WALLET", "GOOGLE_PAY");

    this.searchHostedPaymentPages(1, 25, this.state.hostedPaymentPageQuery);

    axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}/peek`, {
      headers: this.generateRequestHeaders()
    }).then(response => {
      this.setState(prevState => ({
        ...prevState,
        allChargeCodes: response.data.chargeCodes
      }));

      if(!this.state?.allChargeCodes || this.state.allChargeCodes.length === 0){

        Object.entries(charges).forEach(([key, value]) => {
          this.state.allChargeCodes.push({
            code: key,
            name: value
          });
        });
      }

    }).catch(error => {
      this.handleValidation(error);
      this.props.history.push("");
    });

    this.props.handleRefresh(this.props?.company?.id)
  }

  /**
   * Handle selecting a hosted payment page record by bringing up the modal with hosted payment page values
   * pre-filled.
   *
   * @param hostedPaymentPageId - The ID of the hosted payment page selected.
   */
  editHostedPaymentPage(hostedPaymentPageId) {
    axios
      .get(
        `${constants.REACT_APP_HOST_API_URL}/hosted_payment_page/${hostedPaymentPageId}`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          hostedPaymentPage: response.data,
          customFields: response.data.customFields,
          creditMerchantAccountList: response.data.creditMerchantAccountList,
          walletMerchantAccountList: response.data.walletMerchantAccountList,
        }));

        this.searchDocuments();

        let selectedChargeCodes = [];
        let allChargeCodes = this.state.allChargeCodes;

        if(response.data?.chargeCodes && response.data?.chargeCodes.length > 0) {

          let chargeCodes = response.data.chargeCodes;

          chargeCodes.forEach((chargeCode) => {

            allChargeCodes.forEach((allChargeCode)=>{

              if(allChargeCode.code === chargeCode.code){
                selectedChargeCodes.push({
                  value: allChargeCode.code,
                  label: allChargeCode.name
                });
              }
            })
          });

        } else {

          allChargeCodes.forEach((allChargeCode)=>{

            selectedChargeCodes.push({
              value: allChargeCode.code,
              label: allChargeCode.name
            });
          })
        }

        this.setState((prevState) => ({
          ...prevState,
          selectedChargeCodes: selectedChargeCodes,
        }));

        $("#hosted-payment-page").modal("show");
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Initialize a new instance of a hosted payment page object when the user clicks the appropriate button.
   */
  initHostedPaymentPage() {
    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/hosted_payment_page/new`)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          hostedPaymentPage: {
            ...response.data,
            creditMerchantAccountList: {
              'VISA': null,
              'UNION_PAY': null
            },
            walletMerchantAccountList: {
              'APPLE_PAY': null,
              'GOOGLE_PAY': null
            }
          },
          customFields: {},
        }));

        let selectedChargeCodes = [];
        let allChargeCodes = this.state.allChargeCodes;

        allChargeCodes.forEach((allChargeCode)=>{

          selectedChargeCodes.push({
            value: allChargeCode.code,
            label: allChargeCode.name
          });
        })

        this.setState((prevState) => ({
          ...prevState,
          selectedChargeCodes: selectedChargeCodes,
        }));

      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Delete the selected hosted payment page.
   */
  deleteHostedPaymentPage() {
    axios
      .delete(
        `${constants.REACT_APP_HOST_API_URL}/hosted_payment_page/${this.state.hostedPaymentPage.id}/delete`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState({
          validationList: [
            {
              fields: {},
              alert: {
                type: "primary",
                code: "admin.companies.hosting.deleted",
              },
            },
          ],
        });

        this.searchHostedPaymentPages(
          this.state.hostedPaymentPageList.page,
          this.state.hostedPaymentPageList.recordsPerPage,
          this.state.hostedPaymentPageQuery
        );

        $("#hosted-payment-page").modal("hide");
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Handle the submission of the form. Use the create date field of the hosted payment page in the state to determine
   * if the user is editing or creating a new hosted payment page. Upon successful submission of the form, refresh the
   * table and hide the modal.
   *
   * @param event - The event container.
   */
  saveHostedPaymentPage(event) {
    event.preventDefault();

    let hostedPaymentPage = this.state.hostedPaymentPage;
    

    hostedPaymentPage.companyId = this.props.match.params.companyId;
    hostedPaymentPage.customFields = this.state.customFields;
    hostedPaymentPage.creditMerchantAccountList = this.state.creditMerchantAccountList;
    hostedPaymentPage.walletMerchantAccountList = this.state.walletMerchantAccountList;
    hostedPaymentPage.splitItEnabled = hostedPaymentPage?.splitItMerchantAccountId ? true : false;

    let chargeCodes = [];
    let allChargeCodes = this.state.allChargeCodes;

    if(this.state?.selectedChargeCodes && this.state.selectedChargeCodes.length > 0) {

      let selectedChargeCodes = this.state.selectedChargeCodes;

      selectedChargeCodes.forEach((selectedChargeCode) => {

        allChargeCodes.forEach((allChargeCode)=>{

          if(allChargeCode.code === selectedChargeCode.value){
            chargeCodes.push({
              code: allChargeCode.code,
              name: allChargeCode.name
            });
          }
        })
      });
    }

    hostedPaymentPage.chargeCodes = chargeCodes;

    Object.entries(hostedPaymentPage.creditMerchantAccountList).forEach(
      ([key, value]) => {
        if (value === "") {
          delete hostedPaymentPage.creditMerchantAccountList[key];
        }
      }
    );

    Object.entries(hostedPaymentPage.walletMerchantAccountList).forEach(
        ([key, value]) => {
          if (value === "") {
            delete hostedPaymentPage.walletMerchantAccountList[key];
          }
        }
    );

    //if the send email checkbox is checked, always make property selection mandatory
    if(hostedPaymentPage?.sendPaymentEmailToPm) {
      hostedPaymentPage.propertySelectionRequired = true;
    }

    // If property selection is required, make the unit always display and lock value
    if(hostedPaymentPage?.propertySelectionRequired){
      hostedPaymentPage.unitDisplayed = true;
    }

    if (this.state.hostedPaymentPage.createDate == null) {
      axios
        .post(`${constants.REACT_APP_HOST_API_URL}/create`, hostedPaymentPage, {
          headers: this.generateRequestHeaders(),
        })
        .then((response) => {
          this.setState({
            validationList: [
              {
                fields: {},
                alert: {
                  type: "primary",
                  code: "admin.companies.hosting.created",
                },
              },
            ],
          });

          this.searchHostedPaymentPages(
            this.state.hostedPaymentPageList.page,
            this.state.hostedPaymentPageList.recordsPerPage,
            this.state.hostedPaymentPageQuery
          );

          $("#hosted-payment-page").modal("hide");
        })
        .catch((error) => {
          this.handleValidation(error);
        });
    } else {
      axios
        .put(`${constants.REACT_APP_HOST_API_URL}/update`, hostedPaymentPage, {
          headers: this.generateRequestHeaders(),
        })
        .then((response) => {
          this.setState({
            validationList: [
              {
                fields: {},
                alert: {
                  type: "primary",
                  code: "saved",
                  message: "Changes have been saved",
                },
              },
            ],
          });

          this.searchHostedPaymentPages(
            this.state.hostedPaymentPageList.page,
            this.state.hostedPaymentPageList.recordsPerPage,
            this.state.hostedPaymentPageQuery
          );

          $("#hosted-payment-page").modal("hide");
        })
        .catch((error) => {
          this.handleValidation(error);
        });
    }

    window.scrollTo(0, 0);
  }

  /**
   * Upload a document to the list of documents for a specific hosted payment page.
   *
   * @param event - The event container.
   */
  uploadDocument(event) {
    event.preventDefault();

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/hosted_payment_page/${this.state.hostedPaymentPage.id}/upload`,
        this.state.document.file,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState({
          validationList: [
            {
              fields: {},
              alert: {
                type: "primary",
                code: "common.documents.created",
                message: "Upload successful",
              },
            },
          ],
        });

        this.searchDocuments();
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Select a document and bring up the delete document confirmation modal.
   *
   * @param document - The selected document.
   */
  selectDocument(document) {
    this.setState((prevState) => ({
      ...prevState,
      document: document,
    }));

    $("#hosted-payment-page-documents").modal("hide");
    $("#delete-document").modal("show");
  }

  /**
   * Delete the selected document.
   */
  deleteDocument() {
    axios
      .delete(
        `${constants.REACT_APP_HOST_API_URL}/document/${this.state.document.id}/delete`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.searchDocuments();

        $("#hosted-payment-page-documents").modal("show");
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Update the data table of hosted payment pages.
   *
   * @param page - The page to display.
   * @param recordsPerPage - The amount of records to display on each page.
   * @param query - The search query.
   */
  searchHostedPaymentPages(page, recordsPerPage, query) {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/hosted_payment_page/search?recordsPerPage=${recordsPerPage}&page=${page}`,
        {
          orderBy: query.orderBy,
          orderByFields: query.orderByFields,
          conditionList: query.conditionList,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          hostedPaymentPageList: response.data,
          hostedPaymentPageQuery: {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
          },
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Perform a search for all available documents related to the hosted payment page for custom fields that require an
   * image URL or document URL.
   */
  searchDocuments() {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/document/search`,
        {
          orderBy: "ASC",
          orderByFields: ["createDate"],
          conditionList: [
            {
              type: "STRING",
              logicalOperator: "AND",
              openBrackets: null,
              closeBrackets: null,
              fieldName: "recordId",
              operator: "EQUALS",
              fieldValue: this.state.hostedPaymentPage.id,
            },
          ],
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          documentList: response.data.records,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
   * field, the same merchant account will be applies to all credit/debit card brands available.
   *
   * @param event - The event container.
   */
  handleChangeCreditMerchantAccount(event) {
    event.preventDefault();

    let creditMerchantAccountList = this.state.hostedPaymentPage
      .creditMerchantAccountList;
    let creditMerchantAccountId = event.target.value;

    creditMerchantAccountList["VISA"] = creditMerchantAccountId;
    creditMerchantAccountList["VISA_ELECTRON"] = creditMerchantAccountId;
    creditMerchantAccountList["MASTERCARD"] = creditMerchantAccountId;
    creditMerchantAccountList["MAESTRO"] = creditMerchantAccountId;
    creditMerchantAccountList["AMERICAN_EXPRESS"] = creditMerchantAccountId;
    creditMerchantAccountList["DISCOVER"] = creditMerchantAccountId;
    creditMerchantAccountList["DINERS_CLUB"] = creditMerchantAccountId;
    creditMerchantAccountList["JCB"] = creditMerchantAccountId;

    this.setState((prevState) => ({
      ...prevState,
      creditMerchantAccountList: {
        ...prevState.creditMerchantAccountList,
        ...creditMerchantAccountList
      },
    }));
  }

  /**
   * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
   * field, the same merchant account will be applies to all credit/debit card brands available.
   *
   * @param event - The event container.
   */
  handleChangeUnionPayMerchantAccount(event) {
    event.preventDefault();

    let creditMerchantAccountList = this.state.hostedPaymentPage
      .creditMerchantAccountList;

    creditMerchantAccountList["UNION_PAY"] = event.target.value;

    this.setState((prevState) => ({
      ...prevState,
      creditMerchantAccountList: creditMerchantAccountList,
    }));
  }

  /**
   * Handle changes to the default Apple Pay merchant account field. By selecting a merchant account from this
   * field, the same merchant account will be applies to Apple Pay  available.
   *
   * @param event - The event container.
   */
  handleChangeApplePayMerchantAccount(event) {
    event.preventDefault();

    let walletMerchantAccountList = {"APPLE_PAY": null};

    let applePayMerchantAccountId = event.target.value;

    walletMerchantAccountList["APPLE_PAY"] = applePayMerchantAccountId;

    this.setState((prevState) => ({
      ...prevState,
      walletMerchantAccountList: {
        ...prevState.walletMerchantAccountList,
        ...walletMerchantAccountList
      },
    }));
  }

  /**
   * Handle changes to the default Google Pay merchant account field. By selecting a merchant account from this
   * field, the same merchant account will be applies to Apple Pay  available.
   *
   * @param event - The event container.
   */
  handleChangeGooglePayMerchantAccount(event) {
    event.preventDefault();

    let walletMerchantAccountList = {"GOOGLE_PAY": null};

    let googlePayMerchantAccountId = event.target.value;

    walletMerchantAccountList["GOOGLE_PAY"] = googlePayMerchantAccountId;

    this.setState((prevState) => ({
      ...prevState,
      walletMerchantAccountList: {
        ...prevState.walletMerchantAccountList,
        ...walletMerchantAccountList
      },
    }));
  }

  /**
   * Handle change to the chargecodes field of hostedPaymentPage.
   * @param selectedOptions -
   */
  handleChangeChargeCodesList(selectedOptions){

    this.setState(prevState => ({
      ...prevState,
      selectedChargeCodes: selectedOptions
    }));
  }

  /**
   * Handle changes to the Formatted Property Label
   *
   * @param event - The event container.
   *
   **/
  handleChangeFormattedPropertyLabel(event) {
    event.persist();

    let field = this.state.hostedPaymentPage;

    if(event.target.checked){

      field[event.target.name] = 'NAME';

    } else {

      field[event.target.name] = 'ADDRESS';
    }

    this.setState(prevState => ({
      ...prevState,
      hostedPaymentPage: field,
    }));
  }

  /**
   * Render the component.
   *
   * @returns {*} - The hosted payment page management component.
   */
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>
        <Alert
          validationList={this.state.validationList}
          validationType="primary"
        />

        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">Hosted Payment Pages</div>
              <div className="col text-right">
                <div
                  data-toggle="modal"
                  data-target="#hosted-payment-page"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.initHostedPaymentPage()}
                >
                  <FontAwesomeIcon icon={["fas", "plus"]} className="fa-fw" />{" "}
                  Create Hosted Payment Page
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={{ pageName: "Page Name", pageUrl: "Page URL" }}
            columnWidths={["40%", "60%"]}
            headerClass="c-pointer"
            data={this.state.hostedPaymentPageList}
            query={this.state.hostedPaymentPageQuery}
            sortEnabled={false}
            recordsEnabled={true}
            paginationEnabled={true}
            updateFunction={this.searchHostedPaymentPages}
          >
            <tbody>
              {this.state.hostedPaymentPageList.records.map((data, key) => {
                return (
                  <tr>
                    <td
                      key={key}
                      onClick={() => this.editHostedPaymentPage(data.id)}
                      className="c-pointer"
                    >
                      <div className="text-wrap">{data.pageName}</div>
                    </td>
                    <td>
                      <div className="text-break">
                        <a target="_blank" rel="noopener noreferrer" href={"/pay/" + data.pageName}>
                          {window.location.host}/pay/{data.pageName}
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div
          className="modal fade"
          id="hosted-payment-page"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="hosted-payment-page-label"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content shadow">
              <form onSubmit={this.saveHostedPaymentPage}>
                <div className="modal-header bg-dark text-white">
                  <h5 className="modal-title" id="hosted-payment-page-label">
                    {this.state.hostedPaymentPage["createDate"] == null && (
                      <span className="">Create Hosted Payment Page</span>
                    )}
                    {this.state.hostedPaymentPage["createDate"] != null && (
                      <span className="">Edit Hosted Payment Page</span>
                    )}
                  </h5>
                  <button
                    type="button"
                    className="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <FontAwesomeIcon
                      icon={["fas", "times"]}
                      className="fa-fw va-b mr-2"
                    />
                  </button>
                </div>
                <div className="modal-body">
                  <Alert
                    validationList={this.state.validationList}
                    validationType="danger"
                  />

                  <FieldText
                    id="pageName"
                    label="Page Name"
                    model="hostedPaymentPage"
                    parent={this}
                    value={this.state.hostedPaymentPage["pageName"]}
                  />

                  <FieldText
                    id="companyName"
                    label="Company Name"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["companyName"]}
                  />

                  <FieldText
                    id="heroHeading"
                    label="Heading"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["heroHeading"]}
                  />

                  <FieldText
                    id="heroPoint1"
                    label="Point 1"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["heroPoint1"]}
                  />

                  <FieldText
                    id="heroPoint2"
                    label="Point 2"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["heroPoint2"]}
                  />

                  <FieldText
                    id="heroPoint3"
                    label="Point 3"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["heroPoint3"]}
                  />

                  <FieldText
                    id="heroPoint4"
                    label="Point 4"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["heroPoint4"]}
                  />

                  <FieldText
                    id="formSubheading"
                    label="Form Subheading"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["formSubheading"]}
                  />

                  <FieldText
                    id="noteLabel"
                    label="Alternate Label for Note"
                    model="hostedPaymentPage"
                    maxLength={20}
                    parent={this}
                    value={this.state.hostedPaymentPage["noteLabel"]}
                  />

                  <FieldChargeCodes id="selectedChargeCodes" label="Charge Codes" labelClass="col-form-label-sm align-self-center"
                                    value={this.state.selectedChargeCodes} parent={this} options={this.state.allChargeCodes}
                                    handleChange={this.handleChangeChargeCodesList}/>

                  <React.Fragment>
                    <FieldSelect
                      id="VISA"
                      label="Credit/Debit Card"
                      tooltipText="Select BlueSnap merchant account to be used for Credit Card when Paysafe is selected"
                      parent={this}
                      help="Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards."
                      value={
                        this.state.hostedPaymentPage
                          ?.creditMerchantAccountList?.["VISA"]
                      }
                      handleChange={this.handleChangeCreditMerchantAccount}
                    >
                      <option value="">-</option>
                      {this.state.visaMerchantAccounts && (
                        <React.Fragment>
                          {this.state.visaMerchantAccounts.map(
                            (data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  [{data.paymentProviderId?.split('_')[0]}]{' '}
                                  {data.name} (
                                  {data.accountNumber
                                    ? data.accountNumber + " — "
                                    : ""}
                                  {data.name ? data.name + " — " : ""}
                                  {data.last4
                                    ? "*****" + data.last4 + " — "
                                    : ""}
                                  {formatMessage({
                                    id:
                                      "enum.merchantAccount.accountStatus." +
                                      data.accountStatus,
                                  })}
                                  )
                                </option>
                              );
                            }
                          )}
                        </React.Fragment>
                      )}
                    </FieldSelect>

                    <FieldSelect
                      id="UNION_PAY"
                      label="UnionPay"
                      parent={this}
                      value={
                        this.state.hostedPaymentPage
                          ?.creditMerchantAccountList?.["UNION_PAY"]
                      }
                      handleChange={this.handleChangeUnionPayMerchantAccount}
                    >
                      <option value="">-</option>
                      {this.state.unionPayMerchantAccounts && (
                        <React.Fragment>
                          {this.state.unionPayMerchantAccounts.map(
                            (data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  [{data.paymentProviderId?.split('_')[0]}]{' '}
                                  {data.accountNumber
                                    ? data.accountNumber + " — "
                                    : ""}
                                  {data.name ? data.name + " — " : ""}
                                  {data.last4
                                    ? "*****" + data.last4 + " — "
                                    : ""}
                                  {formatMessage({
                                    id:
                                      "enum.merchantAccount.accountStatus." +
                                      data.accountStatus,
                                  })}
                                </option>
                              );
                            }
                          )}
                        </React.Fragment>
                      )}
                    </FieldSelect>
                  </React.Fragment>

                  <FieldSelect
                    id="bankMerchantAccountId"
                    label="Bank Account"
                    model="hostedPaymentPage"
                    parent={this}
                    value={
                      this.state.hostedPaymentPage["bankMerchantAccountId"]
                    }
                  >
                    <option value="">-</option>
                    {this.state.bankMerchantAccounts && (
                      <React.Fragment>
                        {this.state.bankMerchantAccounts.map((data, key) => {
                          if(this.props?.company?.paymentProviderId === data.paymentProviderId) {
                            return (
                              <option key={key} value={data.id}>
                                [{data.paymentProviderId?.split('_')[0]}]{' '}
                                {data.accountNumber
                                  ? data.accountNumber + " — "
                                  : ""}
                                {data.name ? data.name + " — " : ""}
                                {data.last4 ? "*****" + data.last4 + " — " : ""}
                                {formatMessage({
                                  id:
                                    "enum.merchantAccount.accountStatus." +
                                    data.accountStatus,
                                })}
                              </option>
                            );
                          } else {
                            return null
                          }
                        })}
                      </React.Fragment>
                    )}
                  </FieldSelect>

                  <FieldSelect
                    id="paypalMerchantAccountId"
                    label="Paypal Account"
                    model="hostedPaymentPage"
                    parent={this}
                    value={
                      this.state.hostedPaymentPage["paypalMerchantAccountId"]
                    }
                  >
                    <option value="">-</option>
                    {this.state.paypalMerchantAccounts && (
                      <React.Fragment>
                        {this.state.paypalMerchantAccounts.map((data, key) => {
                          return (
                            <option key={key} value={data.id}>
                              [{data.paymentProviderId?.split('_')[0]}]{' '}
                              {data.accountNumber
                                ? data.accountNumber + " — "
                                : ""}
                              {data.name ? data.name + " — " : ""}
                              {data.last4 ? "*****" + data.last4 + " — " : ""}
                              {formatMessage({
                                id:
                                  "enum.merchantAccount.accountStatus." +
                                  data.accountStatus,
                              })}
                            </option>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </FieldSelect>

                  <FieldSelect
                    id="splitItMerchantAccountId"
                    label="SplitIt"
                    model="hostedPaymentPage"
                    parent={this}
                    value={this.state.hostedPaymentPage['splitItMerchantAccountId']}
                  >
                    <option value="">-</option>
                    {this.state.bankMerchantAccounts &&
                    <React.Fragment>
                        {this.state.bankMerchantAccounts.map((data, key) => {
                          if(data.paymentProviderId === 'BLUESNAP')
                            return (
                                <option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>
                            );
                          return null
                        })}
                    </React.Fragment>
                    }
                  </FieldSelect>
                  <FieldSelect
                      id="applePayMerchantAccountId"
                      label="Apple Pay"
                      parent={this}
                      value={
                        this.state.walletMerchantAccountList?.["APPLE_PAY"]
                      }
                      handleChange={this.handleChangeApplePayMerchantAccount}
                  >
                    <option value="">-</option>
                    {this.state.applePayMerchantAccounts && (
                        <React.Fragment>
                          {this.state.applePayMerchantAccounts.map(
                              (data, key) => {
                                return (
                                    <option key={key} value={data.id}>
                                      [{data.paymentProviderId?.split('_')[0]}]{' '}
                                      {data.name} (
                                      {data.accountNumber
                                          ? data.accountNumber + " — "
                                          : ""}
                                      {data.name ? data.name + " — " : ""}
                                      {data.last4
                                          ? "*****" + data.last4 + " — "
                                          : ""}
                                      {formatMessage({
                                        id:
                                            "enum.merchantAccount.accountStatus." +
                                            data.accountStatus,
                                      })}
                                      )
                                    </option>
                                );
                              }
                          )}
                        </React.Fragment>
                    )}
                  </FieldSelect>

                  <FieldSelect
                      id="googlePayMerchantAccountId"
                      label="Google Pay"
                      parent={this}
                      value={
                        this.state.walletMerchantAccountList?.["GOOGLE_PAY"]
                      }
                      handleChange={this.handleChangeGooglePayMerchantAccount}
                  >
                    <option value="">-</option>
                    {this.state.googlePayMerchantAccounts && (
                        <React.Fragment>
                          {this.state.googlePayMerchantAccounts.map(
                              (data, key) => {
                                return (
                                    <option key={key} value={data.id}>
                                      [{data.paymentProviderId?.split('_')[0]}]{' '}
                                      {data.name} (
                                      {data.accountNumber
                                          ? data.accountNumber + " — "
                                          : ""}
                                      {data.name ? data.name + " — " : ""}
                                      {data.last4
                                          ? "*****" + data.last4 + " — "
                                          : ""}
                                      {formatMessage({
                                        id:
                                            "enum.merchantAccount.accountStatus." +
                                            data.accountStatus,
                                      })}
                                      )
                                    </option>
                                );
                              }
                          )}
                        </React.Fragment>
                    )}
                  </FieldSelect>

                  <FieldSelect
                    id="logo"
                    label="Logo"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["logo"]}
                  >
                    <option value="">Select a file...</option>
                    {this.state.documentList && (
                      <React.Fragment>
                        {this.state.documentList.map((document, key) => {
                          return (
                            <option key={key} value={document.url}>
                              {document.name}
                            </option>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </FieldSelect>

                  <FieldSelect
                    id="background"
                    label="Background"
                    model="customFields"
                    parent={this}
                    value={this.state.customFields["background"]}
                  >
                    <option value="">Select a file...</option>
                    {this.state.documentList && (
                      <React.Fragment>
                        {this.state.documentList.map((document, key) => {
                          return (
                            <option key={key} value={document.url}>
                              {document.name}
                            </option>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </FieldSelect>

                  <FieldCheckbox
                    id="propertySelectionRequired"
                    label="Property Selection"
                    fieldLabel="Is it mandatory for tenants to select a property before making a payment?"
                    model="hostedPaymentPage"
                    parent={this}
                    value={this.state.hostedPaymentPage?.sendPaymentEmailToPm ? this.state.hostedPaymentPage?.sendPaymentEmailToPm : this.state.hostedPaymentPage?.propertySelectionRequired}
                    help="If checked, tenants won't be allowed to make a payment without selecting a property and entering a unit number."
                  />

                  <FieldCheckbox
                      id="formattedPropertyLabel"
                      label="Property Display"
                      fieldLabel="Display property name during property selection."
                      model="hostedPaymentPage"
                      parent={this}
                      value={this.state.hostedPaymentPage?.formattedPropertyLabel === 'NAME'}
                      help="If unchecked, then property address will be displayed during property selection.  If checked, then property name will be displayed."
                      handleChange={this.handleChangeFormattedPropertyLabel}
                  />

                  <FieldCheckbox
                    id="unitDisplayed"
                    label="Unit"
                    fieldLabel="Display Unit field in HPP?"
                    model="hostedPaymentPage"
                    parent={this}
                    disabled={this.state.hostedPaymentPage?.propertySelectionRequired}
                    value={this.state.hostedPaymentPage?.propertySelectionRequired ? this.state.hostedPaymentPage?.propertySelectionRequired : this.state.hostedPaymentPage?.unitDisplayed}                    
                    help="Selection in this checkbox will guide whether the corresponding field is displayed in HPP."
                  />
                  <FieldCheckbox
                    id="unitRequired"
                    label=" "
                    fieldLabel="Unit is a required field?"
                    model="hostedPaymentPage"
                    disabled={!(this.state.hostedPaymentPage?.unitDisplayed || this.state.hostedPaymentPage?.propertySelectionRequired)}
                    parent={this}
                    value={this.state.hostedPaymentPage?.unitRequired}                    
                    help="If checked, tenants will be required to enter a unit for their HPP transaction."
                  />

                  <FieldCheckbox
                    id="sendPaymentEmailToPm"
                    label="Send Email"
                    fieldLabel="Send an email to PM when a tenant makes a transaction for their property via HPP?"
                    model="hostedPaymentPage"
                    parent={this}
                    value={this.state.hostedPaymentPage?.sendPaymentEmailToPm}
                    help="If checked, and tenant selects a property and makes a payment on HPP. The email will be sent to all the PMs who are managing that property on Letus."
                  />

                  <FieldCheckbox
                    id="noteRequired"
                    label="Note"
                    fieldLabel="Is it mandatory for tenants to include a note?"
                    model="hostedPaymentPage"
                    parent={this}
                    value={this.state.hostedPaymentPage['noteRequired']}
                    help="If checked, tenants will be required to enter a note for their HPP transaction."
                  />
                  <FieldCustomTemplateMultiple
                      model='customFields'
                      parent={this}
                      btnName='Add Custom Field'
                  />

                </div>
                <div className="modal-footer bg-secondary rounded-bottom d-block">
                  <div className="row">
                    <div className="col-4">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-lg"
                        onClick={() => $("#hosted-payment-page").modal("hide")}
                      >
                        Close
                      </button>
                    </div>
                    <div className="col-8 text-right">
                      {this.state.hostedPaymentPage["createDate"] != null && (
                        <div
                          className="btn btn-lg btn-primary ml-2"
                          onClick={() => {
                            $("#hosted-payment-page").modal("hide");
                            $("#delete-hosted-payment-page").modal("show");
                          }}
                        >
                          Delete
                        </div>
                      )}

                      {this.state.hostedPaymentPage["createDate"] != null && (
                        <div
                          className="btn btn-lg btn-primary ml-2"
                          onClick={() => {
                            $("#hosted-payment-page").modal("hide");
                            $("#hosted-payment-page-documents").modal("show");
                          }}
                        >
                          Upload Files...
                        </div>
                      )}

                      <ButtonSave />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="hosted-payment-page-documents"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="hosted-payment-page-documents-label"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content shadow">
              <form onSubmit={this.uploadDocument}>
                <div className="modal-header bg-dark text-white">
                  <h5
                    className="modal-title"
                    id="hosted-payment-page-documents-label"
                  >
                    Upload Files
                  </h5>
                  <button
                    type="button"
                    className="close text-white"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <FontAwesomeIcon
                      icon={["fas", "times"]}
                      className="fa-fw va-b mr-2"
                    />
                  </button>
                </div>
                <div className="modal-body bg-secondary">
                  <p className="mb-0">
                    Files uploaded below will be made available when editing
                    image-related fields for this hosted payment page.
                  </p>
                </div>

                <div className="modal-body">
                  <Alert
                    validationList={this.state.validationList}
                    validationType="danger"
                  />

                  {this.state.documentList.map((document, key) => {
                    return (
                      <div key={key} className="list-group mb-2">
                        <div
                          className="list-group-item list-group-item-action c-pointer"
                          onClick={() => this.selectDocument(document)}
                        >
                          <div className="row align-items-center">
                            <div className="col-2 text-center">
                              <img
                                src={document.url}
                                alt={document.name}
                                className="img-fluid"
                              />
                            </div>
                            <div className="col-8">
                              <div className="">{document.name}</div>
                              <small className="mb-0 ml-md-0 small text-muted">
                                Uploaded on{" "}
                                <Moment format="MMM DD, YYYY HH:mm">
                                  {document.createDate}
                                </Moment>
                              </small>
                            </div>
                            <div className="col-2 text-right">
                              <div className="float-right">
                                <FontAwesomeIcon
                                  icon={["far", "trash-alt"]}
                                  className="fa-fw"
                                  size="1x"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <FieldFile
                    id="file"
                    label="File"
                    model="document"
                    labelClass="d-none mb-0"
                    fieldClass="form-control-lg mb-0"
                    fieldColumns="12"
                    labelColums="0"
                    parent={this}
                    value={this.state.document["file"]}
                  />
                </div>
                <div className="modal-footer bg-secondary rounded-bottom d-block">
                  <div className="row">
                    <div className="col-4">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-lg"
                        onClick={() => {
                          $("#hosted-payment-page-documents").modal("hide");
                          $("#hosted-payment-page").modal("show");
                        }}
                      >
                        Back
                      </button>
                    </div>
                    <div className="col-8 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg ml-2"
                      >
                        Upload File
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Modal
          id="delete-hosted-payment-page"
          theme="danger"
          iconType="fas"
          iconName="exclamation-triangle"
          title="Delete Hosted Payment Page"
          body="Are you sure you would like to delete this hosted payment page?"
        >
          <button
            type="button"
            className="btn btn-outline-danger btn-lg"
            data-dismiss="modal"
            onClick={() => {
              $("#hosted-payment-page").modal("show");
            }}
          >
            <FormattedMessage id="button.close" />
          </button>
          <button
            onClick={() => {
              this.deleteHostedPaymentPage();
            }}
            className="btn btn-danger btn-lg"
            data-dismiss="modal"
          >
            Delete Hosted Payment Page
          </button>
        </Modal>

        <Modal
          id="delete-document"
          theme="danger"
          iconType="fas"
          iconName="exclamation-triangle"
          title="Delete File"
          body={`Are you sure you would like to delete ${this.state.document.name}? Hosted payment page elements currently utilizing this file may appear broken as a result, and will require manual review.`}
        >
          <button
            type="button"
            className="btn btn-outline-danger btn-lg"
            data-dismiss="modal"
            onClick={() => {
              $("#hosted-payment-page-documents").modal("show");
            }}
          >
            <FormattedMessage id="button.close" />
          </button>
          <button
            onClick={() => {
              this.deleteDocument();
            }}
            className="btn btn-danger btn-lg"
            data-dismiss="modal"
          >
            Delete File
          </button>
        </Modal>
      </React.Fragment>
    );
  }
}

export default injectIntl(HostedPaymentPages);
